import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  InputAdornment,
  IconButton
} from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import pt from "date-fns/locale/pt-BR";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StoreContext from "../../../contexts/Store/StoreContext";
import DefaultDialog from "../../../components/Dialog";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import { addLicense, updateLicense } from "./services";

const defaultValues = {
  cnpj_contrato: "",
  razao_social_contrato: "",
  cnpj_faturamento: "",
  razao_social_faturamento: "",
  nome_fantasia: "",
  max_extensions: 0,
  expiration_date: null,
  client_type: "",
  on_max_extensions: "notify",
  max_digital_ocean_s: 0,
  max_digital_ocean_m: 0,
  max_digital_ocean_l: 0,
};

const defaultError = {
  cnpj_contrato: false,
  razao_social_contrato: false,
  cnpj_faturamento: false,
  razao_social_faturamento: false,
  nome_fantasia: false,
  expiration_date: false,
  client_type: false,
  max_extensions: false,
  on_max_extensions: false,
  max_digital_ocean_s: false,
  max_digital_ocean_m: false,
  max_digital_ocean_l: false,
};

const NewLicense = (props) => {
  const [data, setData] = useState({ ...defaultValues });
  const [errors, setErrors] = useState({ ...defaultError });
  const [loading, setLoading] = useState(false);
  const { open, setOpen, reload, type, id, oldData } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
    CLIENT_TYPE,
    ACTION_TYPE,
  } = useContext(InterfaceContext);

  const checkFields = () => {
    let result = true;
    let erroMap = {
      cnpj_contrato: { cnpj_contrato: true },
      razao_social_contrato: { razao_social_contrato: true },
      cnpj_faturamento: { cnpj_faturamento: true },
      razao_social_faturamento: { razao_social_faturamento: true },
      client_type: { client_type: true },
    };
    Object.keys(data).forEach((d) => {
      if (
        [
          "cnpj_contrato",
          "cnpj_faturamento",
          "razao_social_contrato",
          "razao_social_faturamento",
          "client_type",
        ].includes(d)
      ) {
        if (data[d] === "") {
          setErrors((error) => ({ ...error, ...erroMap[d] }));
          setLoading(false);
          result = false;
        }
      }
    });
    return result;
  };

  const posAPIActions = ({ verb, type }) => {
    setLoading(false);
    reload(true);
    setOpen(false);
    if (type === "success") {
      setSuccessAlertMsg(`Licença ${verb} com sucesso!`);
      setShowSuccessAlert(true);
    } else {
      setErrorAlertMsg(`Erro ao ${verb} a licença!`);
      setShowErrorAlert(true);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (checkFields()) {
      addLicense({ data: data, token: token })
        .then((response) => {
          posAPIActions({ verb: "cadastrada", type: "success" });
        })
        .catch((err) => {
          posAPIActions({ verb: "cadastrar", type: "error" });
        });
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    if (checkFields()) {
      updateLicense({ id: id, data: data, token: token })
        .then((response) => {
          posAPIActions({ verb: "atualizada", type: "success" });
        })
        .catch((err) => {
          posAPIActions({ verb: "atualizar", type: "error" });
        });
    }
  };

  const handleCopyCNPJ = () => {
    setData((data) => ({
      ...data,
      ...{ cnpj_faturamento: data.cnpj_contrato },
    }))
  }

  const handleCopyRazaoSocial = () => {
    setData((data) => ({
      ...data,
      ...{ razao_social_faturamento: data.razao_social_contrato },
    }))
  }

  useEffect(() => {
    if (open) {
      setErrors((data) => ({ ...data, ...defaultError }));
      if (type === "update") {
        setData((data) => ({ ...data, ...oldData }));
      } else {
        setData((data) => ({ ...data, ...defaultValues }));
      }
    }
  }, [open, type, oldData]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={type === "update" ? "Atualizar Licença" : "Nova Licença"}
      loading={loading}
      type={type}
      handleUpdate={handleUpdate}
      handleSubmit={handleSubmit}
      maxWidth={"md"}
    >
      <TextField
        autoFocus
        disabled={loading}
        error={errors.cnpj_contrato}
        fullWidth
        id="cnpj_contrato"
        label="CNPJ Contrato"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ cnpj_contrato: event.target.value },
          }))
        }
        required
        sx={{ width: "35%", mr: "5%" }}
        value={data.cnpj_contrato}
        variant="standard"
      />
      <TextField
        disabled={loading}
        error={errors.razao_social_contrato}
        fullWidth
        id="razao_social_contrato"
        label="Razão Social Contrato"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ razao_social_contrato: event.target.value },
          }))
        }
        required
        sx={{ width: "60%" }}
        value={data.razao_social_contrato}
        variant="standard"
      />
      <TextField
        disabled={loading}
        error={errors.cnpj_faturamento}
        fullWidth
        id="cnpj_faturamento"
        label="CNPJ Faturamento"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ cnpj_faturamento: event.target.value },
          }))
        }
        required
        sx={{ width: "35%", mr: "5%" }}
        value={data.cnpj_faturamento}
        variant="standard"
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="Copiar CNPJ contrato"
                onClick={handleCopyCNPJ}
                edge="end"
              >
               <CopyAllIcon />
              </IconButton>
            </InputAdornment>
        }}
      />
      <TextField
        disabled={loading}
        error={errors.razao_social_faturamento}
        fullWidth
        id="razao_social_faturamento"
        label="Razão Social Faturamento"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ razao_social_faturamento: event.target.value },
          }))
        }
        required
        sx={{ width: "60%" }}
        value={data.razao_social_faturamento}
        variant="standard"
        InputProps={{
          endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="Copiar razão social contrato"
              onClick={handleCopyRazaoSocial}
              edge="end"
            >
             <CopyAllIcon />
            </IconButton>
          </InputAdornment>
        }}
      />
      <TextField
        disabled={loading}
        error={errors.nome_fantasia}
        fullWidth
        id="nome_fantasia"
        label="Nome Fantasia"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ nome_fantasia: event.target.value },
          }))
        }
        value={data.nome_fantasia}
        variant="standard"
      />

      <FormControl variant="standard" sx={{ width: "45%", mt: 1, mr: "5%" }}>
        <InputLabel required error={errors.client_type} id="select_client_type">
          Tipo de Cliente
        </InputLabel>
        <Select
          disabled={loading}
          error={errors.client_type}
          id="client_type"
          label="Tipo de Cliente"
          labelId="select_client_type"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ client_type: event.target.value },
            }))
          }
          value={data.client_type !== null ? data.client_type : ""}
        >
          {Object.values(CLIENT_TYPE).map((client_type, key) => (
            <MenuItem key={key} value={client_type.id}>
              {client_type.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          label="Data do término do contrato"
          onChange={(newValue) => {
            setData((data) => ({
              ...data,
              ...{ expiration_date: +newValue },
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              variant="standard"
              sx={{ width: "45%", ml: "5%", mt: 1 }}
              error={errors.expiration_date}
            />
          )}
          value={data.expiration_date}
        />
      </LocalizationProvider>
      <TextField
        disabled={loading}
        error={errors.max_extensions}
        id="max_extensions"
        label="Qtd. de Ramais contratados"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_extensions: event.target.value },
          }))
        }
        sx={{ width: "45%", mr: "5%" }}
        type="number"
        value={data.max_extensions !== "" ? data.max_extensions : 0}
        variant="standard"
      />
      <FormControl variant="standard" sx={{ width: "45%", mt: 1, ml: "5%" }}>
        <InputLabel
          error={errors.on_max_extensions}
          id="select_on_max_extensions"
        >
          Quando atingir o máximo de ramais:
        </InputLabel>
        <Select
          disabled={loading}
          error={errors.on_max_extensions}
          id="on_max_extensions"
          label="Quando Atingir o Máximo de ramais:"
          labelId="select_on_max_extensions"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ on_max_extensions: event.target.value },
            }))
          }
          value={data.on_max_extensions !== null ? data.on_max_extensions : ""}
        >
          {Object.values(ACTION_TYPE).map((on_max_extensions, key) => (
            <MenuItem key={key} value={on_max_extensions.id}>
              {on_max_extensions.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider sx={{ mt: 4, fontWeight: "bold" }}>
        Quantidade e tipo de servidores permitidos na Digital Ocean.
      </Divider>
      <TextField
        disabled={loading}
        error={errors.max_digital_ocean_s}
        helperText={"2 vCPUs - 2 GB memória RAM - 60 GB Storage"}
        id="max_digital_ocean_s"
        label="Pequeno"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_digital_ocean_s: event.target.value },
          }))
        }
        sx={{ width: "28%", mr: "5%" }}
        type="number"
        value={data.max_digital_ocean_s !== "" ? data.max_digital_ocean_s : 0}
        variant="standard"
      />
      <TextField
        disabled={loading}
        error={errors.max_digital_ocean_m}
        helperText={"2 vCPUs - 4 GB memória RAM - 80 GB Storage"}
        id="max_digital_ocean_m"
        label="Médio"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_digital_ocean_m: event.target.value },
          }))
        }
        sx={{ width: "28%", ml: "2%", mr: "2%" }}
        type="number"
        value={data.max_digital_ocean_m !== "" ? data.max_digital_ocean_m : 0}
        variant="standard"
      />
      <TextField
        disabled={loading}
        error={errors.max_digital_ocean_l}
        helperText={"4 vCPUs - 8 GB memória RAM - 160 GB Storage"}
        id="max_digital_ocean_l"
        label="Grande"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_digital_ocean_l: event.target.value },
          }))
        }
        sx={{ width: "30%", ml: "5%" }}
        type="number"
        value={data.max_digital_ocean_l !== "" ? data.max_digital_ocean_l : 0}
        variant="standard"
      />
    </DefaultDialog>
  );
};

export default NewLicense;
