import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import BoxCustom from "../../components/Box";
import DefaultTable from "../../components/Table";
import StoreContext from "../../contexts/Store/StoreContext";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";
import NewServer from "./New";
import { ActionsRow } from "./ActionsRow";
import { Filter } from "./Filter";
import { getServers } from "./services";

export const HospedagemView = (props) => {
  const { data } = props;
  if (data.in_cloud) {
    return "DIGITAL OCEAN";
  } else {
    return "LOCAL";
  }
};

export const RamaisView = (props) => {
  const { data } = props;
  const theme = useTheme();
  if (data.max_extensions > 0) {
    return (
      <Tooltip title="Máximo/Utilizados" arrow>
        <Typography
          variant="body1"
          sx={{
            color:
              data.max_extensions >= data.total_extensions
                ? theme.palette.success.main
                : theme.palette.error.main,
          }}
        >
          {data.max_extensions}/{data.total_extensions}
        </Typography>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Total" arrow>
        <Typography variant="body1">{data.total_extensions}</Typography>
      </Tooltip>
    );
  }
};

export const ChipStatus = (props) => {
  const { STATUS_FINANCIAL, STATUS_SERVER } = useContext(InterfaceContext);
  const { data } = props;
  return (
    <Tooltip
      title={
        <>
          {STATUS_FINANCIAL[data.status_financial].help} <br />
          {STATUS_SERVER[data.status].help}
        </>
      }
      arrow
    >
      <Box justifyContent="center" >
        {STATUS_FINANCIAL[data.status_financial].chip}
        {STATUS_SERVER[data.status].chip}
      </Box>
    </Tooltip>
  );
};

export const LastCheck = (props) => {
  const { data } = props;
  if (data.last_check !== null) {
    let date = new Date(data.last_check);
    return date.toLocaleDateString("default");
  } else {
    return "-";
  }
};

export const IpixVersion = (props) => {
  const { data } = props;
  if (data.ipix_version !== null) {
    return data.ipix_version;
  } else {
    return "-";
  }
};

const headCells = [
  {
    id: "hostname",
    numeric: false,
    label: "hostname",
    actions: null,
    sortable: true,
  },
  {
    id: "ramais",
    numeric: false,
    label: "Ramais",
    actions: RamaisView,
    sortable: false,
  },
  {
    id: "status_financial",
    numeric: false,
    label: "Status",
    actions: ChipStatus,
    sortable: true,
  },
  {
    id: "hospedagem",
    numeric: false,
    label: "Hospedagem",
    actions: HospedagemView,
    sortable: true,
  },
  {
    id: "last_check",
    numeric: false,
    label: "Última Checagem",
    actions: LastCheck,
    sortable: true,
  },
  {
    id: "ipix_version",
    numeric: false,
    label: "Versão IPix",
    actions: IpixVersion,
    sortable: true,
  },
];

const Servers = () => {
  const [rows, setRows] = useState([]);
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [needReload, setNeedReload] = useState(true);
  const [openNewServer, setOpenNewServer] = useState(false);
  const { token } = useContext(StoreContext);
  const { showMenu, setShowErrorAlert, setErrorAlertMsg, setMenuSelected } =
    useContext(InterfaceContext);
  const theme = useTheme();

  useEffect(() => {
    setMenuSelected(3);
    let filter = {};
    if (needReload) {
      setLoading(true);
      getServers({ filter: filter, token: token })
        .then((data) => {
          setRows(data.data);
          setRowsFiltered(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setErrorAlertMsg("Erro em buscar os servidores!");
          setShowErrorAlert(true);
          setLoading(false);
        });
      setNeedReload(false);
    }
  }, [token, needReload, setErrorAlertMsg, setShowErrorAlert, setMenuSelected]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <BoxCustom theme={theme} open={showMenu}>
        <Box sx={{ width: "100%", marginBottom: 4 }}>
          <Paper
            elevation={2}
            sx={{ padding: 1, marginRight: 1, marginLeft: 1 }}
          >
            <Filter
              rows={rows}
              setRows={setRowsFiltered}
              reload={needReload}
              setOpenNewServer={setOpenNewServer}
            />
          </Paper>
        </Box>
        {loading ? (
          <CircularProgress
            size={48}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        ) : (
          <DefaultTable
            rows={rowsFiltered}
            headCells={headCells}
            tableName="Servidores"
            reload={setNeedReload}
            ActionsToolbar={false}
            ActionsRow={ActionsRow}
            defaultOrderBy="hostname"
            checkBoxEnabled
          />
        )}
      </BoxCustom>
      <NewServer
        open={openNewServer}
        setOpen={setOpenNewServer}
        reload={setNeedReload}
        type="new"
      />
    </Box>
  );
};

export default Servers;
