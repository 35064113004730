import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

import BoxCustom from "../../components/Box";
import DefaultTable from "../../components/Table";

import StoreContext from "../../contexts/Store/StoreContext";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";

import NewLicense from "./New";
import { ActionsRow } from "./ActionsRow";
import { ActionsToolbar } from "./ActionsToolbar";
import { Filter } from "./Filter";

import { getLicenses } from "./services";

export const RamaisView = (props) => {
  const { data } = props;
  const theme = useTheme();
  if (data.max_extensions > 0) {
    return (
      <Tooltip title="Contratado/Utilizado" arrow>
        <Typography
          variant="body1"
          sx={{
            color:
              data.max_extensions >= data.total_extensions
                ? theme.palette.success.main
                : theme.palette.error.main,
          }}
        >
          {data.max_extensions}/{data.total_extensions}
        </Typography>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Total" arrow>
        <Typography variant="body1">{data.total_extensions}</Typography>
      </Tooltip>
    );
  }
};

export const ChipStatus = (props) => {
  const { STATUS_FINANCIAL } = useContext(InterfaceContext);
  const { data } = props;
  return (
    <Tooltip title={STATUS_FINANCIAL[data.status_financial].help} arrow>
      {STATUS_FINANCIAL[data.status_financial].chip}
    </Tooltip>
  );
};

export const ChipClientType = (props) => {
  const { CLIENT_TYPE } = useContext(InterfaceContext);
  const { data } = props;
  return CLIENT_TYPE[data.client_type].chip;
};

export const ExpirationDate = (props) => {
  const { data } = props;
  if (data.expiration_date !== null) {
    let date = new Date(data.expiration_date);
    return date.toLocaleDateString("default");
  } else {
    return "-";
  }
};

export const TotalServers = (props) => {
  const { data } = props;
  return data.servers.length;
};

const headCells = [
  {
    id: "nome_fantasia",
    numeric: false,
    label: "Nome Fantasia",
    actions: null,
    sortable: true,
  },
  {
    id: "ramais",
    numeric: false,
    label: "Ramais",
    actions: RamaisView,
    sortable: false,
  },
  {
    id: "total_servers",
    numeric: false,
    label: "Total Servidores",
    actions: TotalServers,
    sortable: false,
  },
  {
    id: "status_financial",
    numeric: false,
    label: "Status",
    actions: ChipStatus,
    sortable: true,
  },
  {
    id: "client_type",
    numeric: false,
    label: "Tipo de Cliente",
    actions: ChipClientType,
    sortable: true,
  },
  {
    id: "expiration_date",
    numeric: false,
    label: "Término de Contrato",
    actions: ExpirationDate,
    sortable: true,
  },
];

const Licenses = () => {
  const [rows, setRows] = useState([]);
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [needReload, setNeedReload] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openNewLicense, setOpenNewLicense] = useState(false);
  const { token } = useContext(StoreContext);
  const {
    showMenu,
    setShowErrorAlert,
    setErrorAlertMsg,
    profile,
    setMenuSelected,
  } = useContext(InterfaceContext);
  const theme = useTheme();
  // const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    setMenuSelected(0);
    if (profile.permission !== null && token !== null && needReload === true) {
      setLoading(true);
      getLicenses({ token: token })
        .then((data) => {
          setRows(data.data);
          setRowsFiltered(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setErrorAlertMsg("Erro em buscar os licenças!");
          setShowErrorAlert(true);
        });
      setNeedReload(false);
    }
  }, [
    token,
    needReload,
    setErrorAlertMsg,
    setShowErrorAlert,
    setMenuSelected,
    loading,
    profile,
  ]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <BoxCustom theme={theme} open={showMenu}>
        <Box sx={{ width: "100%", marginBottom: 4 }}>
          <Paper
            sx={{ padding: 1, marginRight: 1, marginLeft: 1 }}
            elevation={2}
          >
            <Filter
              rows={rows}
              setRows={setRowsFiltered}
              reload={needReload}
              setOpenNewLicense={setOpenNewLicense}
            />
          </Paper>
        </Box>
        {loading ? (
          <CircularProgress
            size={48}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        ) : (
          <DefaultTable
            rows={rowsFiltered}
            headCells={headCells}
            tableName="Licenças"
            reload={setNeedReload}
            ActionsToolbar={
              profile.permission === "super_admin" && ActionsToolbar
            }
            ActionsRow={
              ["super_admin", "group_admin"].includes(profile.permission) &&
              ActionsRow
            }
            defaultOrderBy="nome_fantasia"
            checkBoxEnabled={profile.permission === "super_admin"}
          />
        )}
      </BoxCustom>
      <NewLicense
        open={openNewLicense}
        setOpen={setOpenNewLicense}
        reload={setNeedReload}
        type="new"
      />
    </Box>
  );
};

export default Licenses;
