import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import theme from "./theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import StoreProvider from "../contexts/Store/StoreProvider";
import InterfaceProvider from "../contexts/Interface/InterfaceProvider";
import Licenses from "./Licenses";
import Groups from "./Groups";
import Users from "./Users";
import Login from "./Login";
import Register from "./Register";
import Servers from "./Servers";
import PrivateRoute from "../routes";

const App = () => {
  return (
    <StoreProvider>
      <InterfaceProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register/:group_id" element={<Register />} />
              <Route
                element={
                  <PrivateRoute
                    permission={[
                      "super_admin",
                      "group_admin",
                      "group_tech",
                      "group_view",
                    ]}
                  />
                }
              >
                <Route path="/" element={<Licenses />} />
                <Route path="/servers/" element={<Servers />} />
                <Route path="/users" element={<Users />} />
              </Route>
              <Route element={<PrivateRoute permission={["super_admin"]} />}>
                <Route path="/groups" element={<Groups />} />
              </Route>
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </InterfaceProvider>
    </StoreProvider>
  );
};

export default App;
