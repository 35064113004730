import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import NewLicense from "../New";
import UpdateLogo from "./UpdateLogo";
import UpdateStatus from "../../../components/UpdateStatus";

import { getLicense } from "./services";
import { deleteLicense, updateLicense } from "../services";

export const ActionsRow = (props) => {
  const { row, reload } = props;
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openLogoDialog, setOpenLogoDialog] = useState(false);
  const navigate = useNavigate();

  const [oldData, setOldData] = useState({
    name: "",
    email: "",
    password: "",
    permission: "",
    group_id: null,
  });
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
    profile,
  } = useContext(InterfaceContext);

  const handleDelete = () => {
    deleteLicense({ id: row.id, token: token })
      .then((data) => {
        setSuccessAlertMsg("Licença removida com sucesso!");
        setShowSuccessAlert(true);
        reload(true);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em remover o licença!");
        setShowErrorAlert(true);
      });
  };

  const handleUpdate = () => {
    getLicense({ id: row.id, token: token })
      .then((data) => {
        setOldData((olddata) => ({
          ...olddata,
          ...data.data,
        }));
        setOpenUpdate(true);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em buscar dados do usuário!");
        setShowErrorAlert(true);
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(row.id);
    setSuccessAlertMsg("Licença copiada");
    setShowSuccessAlert(true);
  };
  const handleUpdateStatus = ({ rowData, data }) => {
    return updateLicense({
      id: rowData.id,
      data: data,
      token: token
    })
  }

  return (
    <>
      <Tooltip title="Copiar Licença" arrow>
        <IconButton color="primary" aria-label="copy" onClick={handleCopy}>
          <CopyAllIcon />
        </IconButton>
      </Tooltip>
      {["super_admin", "group_admin"].includes(profile.permission) &&
        row.client_type === "partner" && (
          <Tooltip
            title="Alterar Logo"
            arrow
            onClick={() => setOpenLogoDialog(true)}
          >
            <IconButton color="primary" aria-label="edit">
              <ImageOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      {profile.permission === "super_admin" && (
        <>
          <Tooltip
            title="Alterar Status"
            arrow
            onClick={() => setOpenStatusDialog(true)}
          >
            <IconButton color="primary" aria-label="edit">
              <AttachMoneyOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Servidores"
            arrow
            onClick={() =>
              navigate({
                pathname: "/servers/",
                search: `?license_id=${row.id}`,
              })
            }
          >
            <IconButton color="primary" aria-label="edit">
              <DesktopWindowsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar Licença" arrow onClick={handleUpdate}>
            <IconButton color="primary" aria-label="edit">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Apagar Licença" arrow>
            <IconButton
              color="error"
              aria-label="delete"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <NewLicense
        open={openUpdate}
        setOpen={setOpenUpdate}
        reload={reload}
        type="update"
        id={row.id}
        oldData={oldData}
      />
      <UpdateStatus
        open={openStatusDialog}
        setOpen={setOpenStatusDialog}
        data={row}
        reload={reload}
        apiUpdate={handleUpdateStatus}
      />
      <UpdateLogo
        open={openLogoDialog}
        setOpen={setOpenLogoDialog}
        oldData={row}
        reload={reload}
      />
    </>
  );
};
