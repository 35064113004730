import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";
import { green } from "@mui/material/colors";

const DefaultDialog = (props) => {
  const {
    open,
    setOpen,
    title,
    loading,
    maxWidth,
    type,
    handleUpdate,
    handleSubmit,
    customDisableCheck
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth ? maxWidth : "sm"}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          Fechar
        </Button>
        {["new", "update"].includes(type) && (
          <Button
            disabled={customDisableCheck || loading}
            onClick={type === "update" ? handleUpdate : handleSubmit}
          >
            Salvar
          </Button>
        )}
      </DialogActions>
      {loading && (
        <CircularProgress
          size={48}
          sx={{
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Dialog>
  );
};

export default DefaultDialog;
